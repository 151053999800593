export default {
  API_REPORT: 'SubmitReportDepartment/submitReports',
  CREATE_REPORT: 'SubmitReportDepartment/submitReportDepartments',
  CREATE_REPORT_File: 'SubmitReportDepartment/submit-report-department-upload-file',
  EDIT_REPORT: 'SubmitReportDepartment/submitReportDepartments',
  DELETE_REPORT: 'SubmitReportDepartment/delete-submit-report-department',
  DETAIL_REPORT: 'SubmitReportDepartment/submitReportDepartments/',
  EXPORT_REPORT: 'SubmitReportDepartment/export-report',
  COMBOBOX_TYPE_REPORT: 'ReportClassification/get-combobox-report-type',
  CREATE_REPORT_FORM: 'SubmitReportDepartment/submit-report-department-form',
  LIST_SUBMIT_REPORT_ACCIDENT_TITLES: 'SubmitReportDepartment/list-submit-report-deparment-titles',
  CREATE_SUBMIT_REPORT_ACCIDENT_TITLES: 'SubmitReportDepartment/update-submit-report-deparment-title',
  LIST_SUBMIT_REPORT_ELEMENT: 'SubmitReportDepartment/list-submit-report-department-accident-sector',
  DELETE_SUBMIT_REPORT_ELEMENT: 'SubmitReportDepartment/delete-submit-report-department-accident-sector',
  COMBOBOX_TYPEOFPROFESSION: 'BussinessSector/get-combobox-treeview',
  CREATE_SUBMIT_REPORT_ELEMENT: 'SubmitReportDepartment/add-submit-report-department-accident-sector',
}
