<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      centered
      size="xl"
      hide-footer
      title="Chỉnh sửa nộp báo cáo"
      @hidden="resetData"
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="injury"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <TableTotalAccident
        v-if="isRendered('injury')"
        id="injury"
        class="tab-pane"
        :submitReportDepartmentId="submitReportDepartmentId"
        :class="{active:activeTab==='injury'}"
      />
      <TableJobAccident
        v-if="isRendered('job')"
        id="job"
        class="tab-pane"
        :submitReportDepartmentId="submitReportDepartmentId"
        :class="{active:activeTab==='job'}"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import MyTabs from '@/components/tabs/MyTabs.vue'

export default {
  components: {
    BModal,
    MyTabs,
    TableTotalAccident: () => import('./tags-edit/TableTotalAccident.vue'),
    TableJobAccident: () => import('./tags-edit/TableJobAccident.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    submitReportDepartmentId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: 'injury',
          title: 'Tiêu chí báo cáo tổng số tai nạn',
          icon: 'FileIcon',
          isRendered: false,
        },
        {
          key: 'job',
          title: 'Phân loại theo ngành',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
      ],
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    activeTab(val) {
      if (val === null) {
        this.activeTab = 'injury'
        this.listTabs[0].isRendered = true
      }
    },
  },

  async created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'injury'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    resetData() {
      this.activeTab = null
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
