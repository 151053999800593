var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"lg","title":"Báo cáo an toàn vệ sinh lao động","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":""},on:{"ok":function($event){return _vm.handleCreate($event)},"hidden":_vm.resetData}},[_c('div',{staticClass:"modal-report"},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Loại báo cáo "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":_vm.typeReport},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item .id; },"label":"name","options":_vm.dataCombobox,"placeholder":'-- Chọn loại báo cáo --'},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.reportTypeId),callback:function ($$v) {_vm.$set(_vm.dataInput, "reportTypeId", $$v)},expression:"dataInput.reportTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Năm "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"year","rules":"required","custom-messages":_vm.year},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.year),callback:function ($$v) {_vm.$set(_vm.dataInput, "year", $$v)},expression:"dataInput.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.isUploadFromFile)?_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("File đính kèm"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":_vm.isUploadFromFile ? 'required': '',"custom-messages":{required: 'File đính kèm là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-input',{attrs:{"disabled":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.fileName),callback:function ($$v) {_vm.$set(_vm.dataInput, "fileName", $$v)},expression:"dataInput.fileName"}}),_c('input',{ref:"upload-file",staticClass:"d-none",attrs:{"type":"file","accept":"application/pdf","disabled":_vm.type === 'edit' ? true : false},on:{"change":_vm.importFileExcel}}),_c('b-button',{staticClass:"btn-button-file ml-1",attrs:{"variant":errors.length > 0 ? 'outline-danger':'outline-primary'},on:{"click":_vm.uploadFile}},[_c('feather-icon',{class:errors.length > 0 ? 'text-danger':'text-primary',attrs:{"icon":"UploadIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,993500442)})],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }