<template>
  <div id="inspectorId">
    <div
      id="filter-custom"
      class="page-container mb-2"
    >
      <h3
        class="header"
      >
        Bộ lọc
      </h3>
      <b-row>
        <!-- Người lao động -->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Năm <span class="required"></span></label>
            <v-select
              :reduce="label => label"
              :options="dataFilterYear"
              :placeholder="'Năm'"
              @input="filterYear"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
    </div>
    <div class="page-container-table">
      <div class="d-flex justify-content-between button-header ml-1 mr-1">
        <div
          id="btn-all-header"
          class="d-flex"
        >
          <!-- -------------Delete------------- -->
          <button-component
            v-b-tooltip.hover.top="'Xóa nhiều'"
            class="button-icon mr-1"
            content-btn=""
            variant="danger"
            icon-btn="Trash2Icon"
            :disabled="!showBtnMultiDelete"
            @click="$emit('clickDelete')"
          />

        </div>
        <div class="header-action">
          <search
            class="search-input"
            @change="search"
          />
          <!-- <button-component
            class="button-add"
            :content-btn="contentBtnAdd"
            variant="primary"
            icon-btn="PlusIcon"
            @click="$emit('clickAdd')"
          /> -->
          <b-dropdown
            text="Thêm báo cáo"
            class="button-add"
            variant="primary"
          >
            <b-dropdown-item @click="showModalReport(false)">
              Thêm tự động
            </b-dropdown-item>
            <b-dropdown-item @click="showModalReport(true)">
              Thêm từ file
            </b-dropdown-item>
            <b-dropdown-item @click="handleInForm">
              Thêm báo cáo từ form
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!--phần header-->
      <!-- <button-all-header
        :contentBtnAdd="'Thêm báo cáo'"
        :arrayExcel="[]"
        :hideExportFile="false"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideDowload="false"
        @clickDelete="deleteItems"
        @clickExportExcel="() => {}"
        @clickAdd="showModalReport"
        @search="search($event)"
      /> -->
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Chức năng -->
          <span v-if="props.column.field == 'action'">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="'Xem báo cáo'"
                icon="EyeIcon"
                size="18"
                class="text-body"
                @click="showPDF(props.row.id, props.row.name)"
              />
            </span>
            <span class="win ml-2">
              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    v-b-tooltip.hover.top="'Tải xuống'"
                    icon="DownloadIcon"
                    size="16"
                    class="text-body"
                  />
                </template>
                <!-- xem chi tiet  -->
                <b-dropdown-item
                  @click="downloadFile(props.row.id, 'Word', props.row.name)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Xuất file Word</span>
                </b-dropdown-item>
                <!-- xem bao cao  -->
                <b-dropdown-item @click="downloadFile(props.row.id, 'PDF', props.row.name)">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Xuất file PDF</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

            <span
              v-if="['Form'].includes(props.row.submitReportType)
                && ['ATVSLDSO02', 'ATVSLDSO03'].includes(props.row.code)"
            >
              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body"
                  />
                </template>
                <b-dropdown-item
                  @click="showModalEdit(props.row.id)"
                >
                  <feather-icon
                    v-b-tooltip.hover.top="'Chỉnh sửa'"
                    icon="EditIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Chỉnh sửa</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteItem(props.row.id)">

                  <feather-icon
                    v-b-tooltip.hover.top="'Xóa'"
                    icon="Trash2Icon"
                    size="16"
                    class="mr-50 win"
                  />
                  <span>Xoá</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

            <span
              v-else
              class="win ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="16"
                class="mr-50"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!--Tạo kiểm định viên-->
      <modal-create-report
        :id="modalCreateReport"
        :isUploadFromFile="isUploadFromFile"
        @accept="handlModal"
      />
      <!-- modal xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfSrc"
        @resetModal="resetModal"
      />

      <!--Tạo form báo cáo-->
      <modal-create-form
        :id="modalCreateForm"
        @accept="handleCreateForm"
      />

      <!--chỉnh sửa báo cáo-->
      <modal-edit-report
        :id="modalEditForm"
        :submitReportDepartmentId="submitReportDepartmentId"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateReport from './components/ModalCreateReport.vue'
import ModalEditReport from './components/ModalEditReport.vue'
import ModalSeePdf from './components/ModalSeePDF.vue'
import ModalCreateForm from './components/ModalCreateForm.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ConstantsApi from '../constants/ConstantsApi'
import Search from '@/components/search/Search.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    ConfirmModal,
    BDropdown,
    BDropdownItem,
    ModalCreateReport,
    vSelect,
    ModalSeePdf,
    Search,
    ButtonComponent,
    ModalCreateForm,
    ModalEditReport,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      pdfSrc: '',
      totalRecord: 10,
      modalSeePdf: 'modal-see-pdf',
      dataFilterYear: [],
      columns: [
        {
          label: 'TÊN BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NĂM',
          field: 'year',
          sortable: false,
        },
        {
          label: 'HÌNH THỨC',
          field: 'submitReportTypeString',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '140px',
        },
      ],
      dataList: [],
      currentPage: 0,
      urlQuery: {
        rType: 'OSH',
        year: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      modalEditForm: 'modal-edit-forms',
      confirmModalId: 'confirm-modal',
      modalCreateForm: 'modal-create-forms',
      modalContent: '',
      modalCreateReport: 'comfirm-create',
      deleteIds: [],
      showBtnMultiDelete: false,
      fileType: '',
      isUploadFromFile: false,
      submitReportDepartmentId: '',
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },
  created() {
    this.fetchData(this.urlQuery)
    this.getYear()
    this.dataFilterYear = this.yearList
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    // Mở modal tạo
    showModalReport(val) {
      this.isUploadFromFile = val
      this.$bvModal.show(this.modalCreateReport)
    },

    showModalEdit(id) {
      this.submitReportDepartmentId = id
      this.$bvModal.show(this.modalEditForm)
    },

    // Mở modal tạo bằng form
    handleInForm() {
      this.$bvModal.show(this.modalCreateForm)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },
    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_REPORT, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thêm báo cáo từ form
    async handleCreateForm(val) {
      await axiosApiInstance.post(ConstantsApi.CREATE_REPORT_FORM, val).then(response => {
        if (response.status === 200) {
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalCreateForm)
          this.fetchData(this.urlQuery)
        }
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thêm báo cáo
    async handlModal(val) {
      let api
      let payload
      if (this.isUploadFromFile) {
        payload = new FormData()
        payload.append('ReportTypeId', val.reportTypeId)
        payload.append('Year', val.year)
        payload.append('IsSecure', val.modelFormData.isSecure)
        payload.append('formFile', val.modelFormData.files)
        payload.append('FileName', val.fileName)
        api = ConstantsApi.CREATE_REPORT_File
      } else {
        payload = {
          ...val,
          year: parseInt(val.year, 10),
        }
        api = ConstantsApi.CREATE_REPORT
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(api, payload).then(response => {
        this.$hideAllPageLoading()
        if (response.status === 200) {
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalCreateReport)
          this.fetchData(this.urlQuery)
        }
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    // Lấy danh sách
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_REPORT, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // Bộ lọc theo năm
    filterYear(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = ''
        this.fetchData(this.urlQuery)
      }
    },

    showPDF(id, name) {
      const paload = {
        submitReportDepartmentId: id,
        reportFormat: 'PDF',
      }
      axiosApiInstance({
        url: 'SubmitReportDepartment/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfSrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file có trong giấy phép lao dộng
    downloadFile(id, val, name) {
      const paload = {
        submitReportDepartmentId: id,
        reportFormat: val,
      }
      axiosApiInstance({
        url: 'SubmitReportDepartment/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = val === 'Word' ? `${name}.docx` : `${name}.pdf`
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    resetModal() {
      this.pdfSrc = null
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }
  .win{
    .btn {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .border-status {
    border-radius: 4px;
  }
  #btn-all-header{
    .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }
   .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
.search-input {
    width: 400px;
}
.button-add {
  margin-right: 0 !important;
  margin-left: 1rem;
}
@media (max-width: 800px) {
  .search-input {
      width: 100% !important;
      margin: 1rem 0 0 0;
  }
  .header-action {
    width: 100%;
    flex-wrap: wrap;
  }
  .button-header{
    flex-wrap: wrap;
  }
  .button-add {
    width:100%;
    // margin-right: 1.5rem !important;
    margin-left: 0;
    margin-top: 1rem;
    button {
      width: 100%;
    }
  }
}
}
</style>
